import React from 'react'
import { Link } from 'gatsby'
import { Head } from '@components'
import * as st from '@assets/styl/404.module.styl'
import { useLanguage } from '../hooks'

const Terms = () => {
  const { t } = useLanguage()
  
  return (
    <>
      <Head pathname="/404/" title="Dimas Construções" />

      <section className={st.core}>
        <div>
          <h1>{t('Page404.title')}</h1>
          <p>
            {t('Page404.paragraph.1')}
            <br />
            {t('Page404.paragraph.2')}
          </p>
          <Link to="/" className="btn">
            {t('Page404.button')}
          </Link>
        </div>
      </section>
    </>
  )
}

export default Terms
